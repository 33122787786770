import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getBlogs } from "../../Services/api";
import "./Blogs.css";
function Blogs() {
  const [data, setData] = useState([]);
 
  const getData = async () => {
    try {
      const resp = await getBlogs();
      setData(resp.data.data);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const sideData = [
    {
      content: <img src="Integrate.jpg" width="337px" height="285px" />,
    },
    {
      title: "Other Events",
      content: "",
    },
    {
      title: "Ads",
      content: "Hello",
    },
  ];

  return (
    <Container fluid>
      <div className="row d-flex title mb-2 mx-4">Blogs</div>
      <div className="row">
        <div className="col-9 row m-0 h-100">
          {data.map((element) => {
            return (
              <div className="shadow mb-4 col-sm-3 p-4 border-css mx-2">
                <div className="row">
                  <div className="col-4">
                    <img
                      src={element.image}
                      alt="image"
                      width="100%"
                      className="img-fluid m-2"
                    />
                  </div>
                  <div className="col-8 text-end">
                    <p className=" p-0 m-0 title">{element.title}</p>
                    <p className="text p-0 m-0 ">
                      <small>{element.author}</small>
                    </p>
                    <p className="text p-0 m-0 ">
                      <small>{element.category}</small>
                    </p>
                  </div>
                </div>
                <div className="border-bottom"></div>
                <p className="m-1" style={{ color: "#1C115D" }}>
                  {element.description}
                </p>
                <div className="border-bottom"></div>
                <div className="d-flex justify-content-center m-2 p-1">
                  <Link to={`/blogs-details/${element._id}`}> Know More</Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-3">
          <div className="d-flex flex-column">
            {sideData.map((element) => {
              return (
                <div className="side mb-3 p-2">
                  <div>
                    <strong className="text-break">{element.title}</strong>
                  </div>
                  <div>{element.content}</div>
                  <div className="mt-2 adjust-words">

                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Blogs;
