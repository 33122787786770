import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getBlogsById } from "../../Services/api";
import "./Blogs.css";

function BlogDetails() {
  const sideData = ["Other Blogs", "Sponsored Posts", "Ads"];
  const { id } = useParams(); // Fetch the blog ID from the URL

  const [data, setData] = useState(null); // Blog data state

  const getData = async (id) => {
    try {
      const resp = await getBlogsById(id); // Fetch the blog details by ID
      if (resp.data.success === 1 && resp.data.data.length > 0) {
        setData(resp.data.data[0]); // Set the first blog in the response
      }
    } catch (error) {
      console.log("Error fetching blog details: ", error);
    }
  };

  useEffect(() => {
    getData(id); // Fetch blog details when component mounts or when id changes
  }, [id]);

  return (
    <Container fluid>
      <div className="row">
        <div className="col-9">
          {data ? (
            <div className="row">
              <div className="col-9">
                <div className="row d-flex title mb-2 m-0">{data.title}</div>
                <b>{data.description}</b>
                <p className="mt-2">
                  {/* If there are any additional details to display */}
                </p>
              </div>
              <div className="col-3">
                <img
                  src={data.image}
                  alt={data.title}
                  width="100%"
                  className="img-fluid"
                />
              </div>
            </div>
          ) : (
            <p>Loading...</p> // Show a loading message while fetching data
          )}
        </div>
        <div className="col-3">
          <div className="d-flex flex-column">
            {sideData.map((element, index) => (
              <div className="side mb-3" key={`b-${index}`}>
                <strong>{element}</strong>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default BlogDetails;
